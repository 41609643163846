/*------------------------------------------------------------------
    [Teal Color Theme]
------------------------------------------------------------------ */
a {
  color: #009688;
}

a:focus, a:hover, a:active {
  color: #009688;
}

/* Heading */
h1 > a:hover, h2 > a:hover, h3 > a:hover, h4 > a:hover, h5 > a:hover, h6 > a:hover {
  color: #009688;
}

/* Selection */
::selection {
  background: #009688;
}

::-webkit-selection {
  background: #009688;
}

::-moz-selection {
  background: #009688;
}

/* Blog Classic */
.blog-classic .blog-classic-content .blog-classic-share .blog-classic-share-item .blog-classic-share-link:hover .blog-share-icon {
  color: #009688;
}

.blog-classic .blog-classic-content .blog-classic-publisher .blog-classic-publisher-name > a:hover {
  color: #009688;
}

/* Blog Grid */
/* Supplemental */
.blog-grid .blog-grid-supplemental-category {
  color: #009688;
}

/* Blog Grid Quote */
.blog-grid-quote .blog-grid-quote-text:hover {
  color: #009688;
}

/* Blog Grid Quote v2 */
.blog-grid-quote-v2 {
  background: #009688;
}

/* Blog Grid Thumb */
.blog-grid-thumb .blog-grid-thumb-body:before {
  background: rgba(0, 150, 136, 0.7);
}

/* Twitter Feed */
.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-link {
  color: #009688;
}

/* Featured Article */
.featured-article .featured-article-content .featured-article-content-title > a:hover {
  color: #009688;
}

/* Footer Tags */
.blog-sidebar-tags > li > a:hover {
  background: #009688;
  border-color: #009688;
}

/* Blog Teaser Content */
.blog-teaser:hover .blog-teaser-overlay {
  background: rgba(0, 150, 136, 0.8);
}

/* Blog Teaser V2 */
.blog-teaser-v2:hover .blog-teaser-v2-overlay {
  background: rgba(0, 150, 136, 0.7);
}

/* News v1 */
/* Heading */
.news-v1 .news-v1-heading .news-v1-heading-tag:hover {
  color: #009688;
}

/* Content */
.news-v1 .news-v1-lists .news-v1-lists-item .news-v1-lists-link:hover {
  color: #009688;
}

/* News v3 */
.news-v3 .news-v3-content .news-v3-subtitle {
  color: #009688;
}

.news-v3 .news-v3-content .news-v3-element:before, .news-v3 .news-v3-content .news-v3-element:after {
  border-bottom-color: #009688;
}

.news-v3 .news-v3-content .news-v3-element .news-v3-element-icon {
  color: #009688;
}

/* News v4 */
.news-v4 .news-v4-title-element-left:after {
  border-bottom-color: #009688;
}

.news-v4 .news-v4-title-element-right:after {
  border-bottom-color: #009688;
}

/* News v5 */
.news-v5 .news-v5-icon {
  color: #009688;
}

/* News v6 */
.news-v6 .news-v6-subtitle {
  color: #009688;
}

/* News v8 Image Effect */
.news-v8:hover .news-v8-img-effect:before {
  background: rgba(0, 150, 136, 0.5);
}

/* News v8 Wrap */
.news-v8 .news-v8-footer-list .news-v8-footer-list-link:hover {
  color: #009688;
}

/* News v8 More */
.news-v8 .news-v8-more .news-v8-more-link {
  background: #009688;
}

/* Accordion v1 */
.accordion-v1 .panel-title > a[aria-expanded="true"] {
  background: #009688;
}

.accordion-v1 .panel-title > a:focus, .accordion-v1 .panel-title > a:hover {
  background: #009688;
}

/* Accordion v2 */
.accordion-v2 .panel-title > a:focus, .accordion-v2 .panel-title > a:hover {
  color: #009688;
}

.accordion-v2 .panel-title > a:focus:after, .accordion-v2 .panel-title > a:hover:after {
  color: #009688;
}

/* Accordion v3 */
.accordion-v3 .panel-title > a:focus, .accordion-v3 .panel-title > a:hover {
  color: #009688;
}

.accordion-v3 .panel-title > a:focus:before, .accordion-v3 .panel-title > a:hover:before {
  color: #009688;
}

/* Accordion v4 */
.accordion-v4 .panel-title > a:before {
  color: #009688;
}

.accordion-v4 .panel-title > a:focus, .accordion-v4 .panel-title > a:hover {
  color: #009688;
}

.accordion-v4 .panel-title > a:focus:before, .accordion-v4 .panel-title > a:hover:before {
  color: #009688;
}

/* Accordion v5 */
.accordion-v5 .panel-title > a:focus, .accordion-v5 .panel-title > a:hover {
  color: #009688;
}

.accordion-v5 .panel-title > a:focus:after, .accordion-v5 .panel-title > a:hover:after {
  color: #009688;
}

/* Blockquotes v1 */
.breadcrumbs-v1 .breadcrumbs-v1-links > li > a:hover {
  color: #009688;
}

.breadcrumbs-v1 .breadcrumbs-v1-links > li.active {
  color: #009688;
}

/* Breadcrumbs v2 */
.breadcrumbs-v2 .breadcrumbs-v2-links > li > a:hover {
  color: #009688;
}

.breadcrumbs-v2 .breadcrumbs-v2-links > li.active {
  color: #009688;
}

/* Breadcrumbs v3 */
.breadcrumbs-v3 .breadcrumbs-v3-links > li > a:hover {
  color: #009688;
}

.breadcrumbs-v3 .breadcrumbs-v3-links > li.active {
  color: #009688;
}

/* Button Teal Color */
.btn-base {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #009688;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base:focus, .btn-base:active:focus, .btn-base.active:focus, .btn-base.focus, .btn-base:active.focus, .btn-base.active.focus {
  outline: none;
}

.btn-base:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base:hover, .btn-base:focus, .btn-base.focus {
  color: #fff;
  background: #009688;
  border-color: #009688;
  text-decoration: none;
}

.btn-base:after, .btn-base:before {
  background: transparent;
}

.btn-base:active, .btn-base.active {
  background-image: none;
  outline: 0;
}

.btn-base.disabled, .btn-base[disabled],
fieldset[disabled] .btn-base {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 4px 9px;
}

.btn-base .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 7px 11px;
}

.btn-base .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 8px 13px;
}

.btn-base .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 10px 15px;
}

/* Buttons Border Teal Color */
.btn-base-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #009688;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #009688;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-brd:focus, .btn-base-brd:active:focus, .btn-base-brd.active:focus, .btn-base-brd.focus, .btn-base-brd:active.focus, .btn-base-brd.active.focus {
  outline: none;
}

.btn-base-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-brd:hover, .btn-base-brd:focus, .btn-base-brd.focus {
  color: #fff;
  background: #009688;
  border-color: #009688;
  text-decoration: none;
}

.btn-base-brd:after, .btn-base-brd:before {
  background: transparent;
}

.btn-base-brd:active, .btn-base-brd.active {
  background-image: none;
  outline: 0;
}

.btn-base-brd.disabled, .btn-base-brd[disabled],
fieldset[disabled] .btn-base-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 4px 9px;
}

.btn-base-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 7px 11px;
}

.btn-base-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 8px 13px;
}

.btn-base-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 10px 15px;
}

/* Button Background Teal Color */
.btn-base-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #009688;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-bg:focus, .btn-base-bg:active:focus, .btn-base-bg.active:focus, .btn-base-bg.focus, .btn-base-bg:active.focus, .btn-base-bg.active.focus {
  outline: none;
}

.btn-base-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-bg:hover, .btn-base-bg:focus, .btn-base-bg.focus {
  color: #fff;
  background: #45b4a9;
  border-color: transparent;
  text-decoration: none;
}

.btn-base-bg:after, .btn-base-bg:before {
  background: transparent;
}

.btn-base-bg:active, .btn-base-bg.active {
  background-image: none;
  outline: 0;
}

.btn-base-bg.disabled, .btn-base-bg[disabled],
fieldset[disabled] .btn-base-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 4px 9px;
}

.btn-base-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 7px 11px;
}

.btn-base-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 8px 13px;
}

.btn-base-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 11px 15px;
}

/* Button Background White Color */
.btn-white-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: #fff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white-bg:focus, .btn-white-bg:active:focus, .btn-white-bg.active:focus, .btn-white-bg.focus, .btn-white-bg:active.focus, .btn-white-bg.active.focus {
  outline: none;
}

.btn-white-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white-bg:hover, .btn-white-bg:focus, .btn-white-bg.focus {
  color: #fff;
  background: #009688;
  border-color: #009688;
  text-decoration: none;
}

.btn-white-bg:after, .btn-white-bg:before {
  background: transparent;
}

.btn-white-bg:active, .btn-white-bg.active {
  background-image: none;
  outline: 0;
}

.btn-white-bg.disabled, .btn-white-bg[disabled],
fieldset[disabled] .btn-white-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Teal Color Slide */
.btn-base-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #009688;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: tranparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-bg-slide:focus, .btn-base-bg-slide:active:focus, .btn-base-bg-slide.active:focus, .btn-base-bg-slide.focus, .btn-base-bg-slide:active.focus, .btn-base-bg-slide.active.focus {
  outline: none;
}

.btn-base-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-bg-slide:hover, .btn-base-bg-slide:focus, .btn-base-bg-slide.focus {
  color: #fff;
  background: #009688;
  border-color: tranparent;
  text-decoration: none;
}

.btn-base-bg-slide:after, .btn-base-bg-slide:before {
  background: #45b4a9;
}

.btn-base-bg-slide:active, .btn-base-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-base-bg-slide.disabled, .btn-base-bg-slide[disabled],
fieldset[disabled] .btn-base-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #00aac0;
  padding: 6px 9px;
}

.btn-base-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #00aac0;
  padding: 9px 11px;
}

.btn-base-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #00aac0;
  padding: 12px 13px;
}

.btn-base-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #00aac0;
  padding: 15px 15px;
}

/* Button White Color Slide */
.btn-white-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: #fff;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white-bg-slide:focus, .btn-white-bg-slide:active:focus, .btn-white-bg-slide.active:focus, .btn-white-bg-slide.focus, .btn-white-bg-slide:active.focus, .btn-white-bg-slide.active.focus {
  outline: none;
}

.btn-white-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white-bg-slide:hover, .btn-white-bg-slide:focus, .btn-white-bg-slide.focus {
  color: #fff;
  background: #fff;
  border-color: transparent;
  text-decoration: none;
}

.btn-white-bg-slide:after, .btn-white-bg-slide:before {
  background: #009688;
}

.btn-white-bg-slide:active, .btn-white-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-white-bg-slide.disabled, .btn-white-bg-slide[disabled],
fieldset[disabled] .btn-white-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Teal Color Slide */
.btn-base-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #009688;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #009688;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-brd-slide:focus, .btn-base-brd-slide:active:focus, .btn-base-brd-slide.active:focus, .btn-base-brd-slide.focus, .btn-base-brd-slide:active.focus, .btn-base-brd-slide.active.focus {
  outline: none;
}

.btn-base-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-brd-slide:hover, .btn-base-brd-slide:focus, .btn-base-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #009688;
  text-decoration: none;
}

.btn-base-brd-slide:after, .btn-base-brd-slide:before {
  background: #009688;
}

.btn-base-brd-slide:active, .btn-base-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-base-brd-slide.disabled, .btn-base-brd-slide[disabled],
fieldset[disabled] .btn-base-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #00aac0;
  padding: 6px 9px;
}

.btn-base-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #00aac0;
  padding: 9px 11px;
}

.btn-base-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #00aac0;
  padding: 12px 13px;
}

.btn-base-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #00aac0;
  padding: 15px 15px;
}

/* Button Background Teal Color */
.btn-base-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #009688;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-bg-anim:focus, .btn-base-bg-anim:active:focus, .btn-base-bg-anim.active:focus, .btn-base-bg-anim.focus, .btn-base-bg-anim:active.focus, .btn-base-bg-anim.active.focus {
  outline: none;
}

.btn-base-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-bg-anim:hover, .btn-base-bg-anim:focus, .btn-base-bg-anim.focus {
  color: #fff;
  background: #009688;
  border-color: transparent;
  text-decoration: none;
}

.btn-base-bg-anim:after, .btn-base-bg-anim:before {
  background: #007d71;
}

.btn-base-bg-anim:active, .btn-base-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-base-bg-anim.disabled, .btn-base-bg-anim[disabled],
fieldset[disabled] .btn-base-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #00aac0;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-base-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #00aac0;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-base-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #00aac0;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-base-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #00aac0;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background White Color */
.btn-white-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: #fff;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white-bg-anim:focus, .btn-white-bg-anim:active:focus, .btn-white-bg-anim.active:focus, .btn-white-bg-anim.focus, .btn-white-bg-anim:active.focus, .btn-white-bg-anim.active.focus {
  outline: none;
}

.btn-white-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white-bg-anim:hover, .btn-white-bg-anim:focus, .btn-white-bg-anim.focus {
  color: #fff;
  background: #fff;
  border-color: transparent;
  text-decoration: none;
}

.btn-white-bg-anim:after, .btn-white-bg-anim:before {
  background: #009688;
}

.btn-white-bg-anim:active, .btn-white-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-white-bg-anim.disabled, .btn-white-bg-anim[disabled],
fieldset[disabled] .btn-white-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Border Teal Color */
.btn-base-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #009688;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #009688;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-brd-anim:focus, .btn-base-brd-anim:active:focus, .btn-base-brd-anim.active:focus, .btn-base-brd-anim.focus, .btn-base-brd-anim:active.focus, .btn-base-brd-anim.active.focus {
  outline: none;
}

.btn-base-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-brd-anim:hover, .btn-base-brd-anim:focus, .btn-base-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #009688;
  text-decoration: none;
}

.btn-base-brd-anim:after, .btn-base-brd-anim:before {
  background: #009688;
}

.btn-base-brd-anim:active, .btn-base-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-base-brd-anim.disabled, .btn-base-brd-anim[disabled],
fieldset[disabled] .btn-base-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #00aac0;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-base-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #00aac0;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-base-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #00aac0;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-base-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #00aac0;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Divider */
.divider-v1 .divider-v1-element-bg, .divider-v2 .divider-v2-element-bg, .divider-v3 .divider-v3-element-bg {
  background: #009688;
}

.divider-v1 .divider-v1-icon, .divider-v2 .divider-v2-icon, .divider-v3 .divider-v3-icon {
  color: #009688;
}

/* Dropcap Base Color */
.dropcap-base {
  color: #009688;
}

/* Dropcap Base Background Color */
.dropcap-base-bg {
  background: #009688;
}

/* Dropcap Base Color Bordered */
.dropcap-base-bordered {
  color: #009688;
  border-color: #009688;
}

/* Dropcap Base Color */
.dropcap-dark {
  color: #34343c;
}

/* Heading v1 */
.heading-v1 .heading-v1-subtitle {
  color: #009688;
}

/* Font Awesome Icons */
.bordered-icon .bordered-icon-box:hover {
  background: #009688;
}

/* Lists */
.lists-base > li > a:hover, .lists-dark > li > a:hover {
  color: #009688;
}

.lists-base > li .lists-item-element, .lists-dark > li .lists-base .lists-item-element, .lists-base .lists-dark > li .lists-item-element {
  color: #009688;
}

/* Lists Base */
.lists-base .lists-item-element-brd {
  color: #009688;
  border-color: #009688;
}

.lists-base .lists-item-element-bg {
  background: #009688;
}

/* Numeric List */
.numeric-base-lists > li > a:hover, .numeric-dark-lists > li > a:hover {
  color: #009688;
}

/* Numeric Base Lists */
.numeric-base-lists > li {
  color: #009688;
}

/* Log In */
.login .login-form-forgot:hover {
  color: #009688;
}

/* Paginations v1 */
.paginations-v1-list > li > a:hover,
.paginations-v1-list > li > span:hover {
  background: rgba(0, 150, 136, 0.5);
}

.paginations-v1-list > li.next > a:hover,
.paginations-v1-list > li.next > span:hover, .paginations-v1-list > li.previous > a:hover,
.paginations-v1-list > li.previous > span:hover {
  color: #009688;
}

.paginations-v1-list > li.active > a,
.paginations-v1-list > li.active > span {
  background: #009688;
}

/* Paginations v2 */
.paginations-v2-list > li > a:hover,
.paginations-v2-list > li > span:hover {
  color: #009688;
  border-bottom-color: #009688;
}

.paginations-v2-list > li.active > a,
.paginations-v2-list > li.active > span {
  color: #009688;
  border-bottom-color: #009688;
}

.paginations-v2-list > li.active > a:hover,
.paginations-v2-list > li.active > span:hover {
  color: #009688;
}

/* Paginations v3 */
.paginations-v3-list > li > a,
.paginations-v3-list > li > span {
  color: #009688;
}

.paginations-v3-list > li > a:hover,
.paginations-v3-list > li > span:hover {
  color: #009688;
}

.paginations-v3-list > li.active > a,
.paginations-v3-list > li.active > span {
  background: #009688;
  border-color: #009688;
}

.paginations-v3-list > li.active > a:hover,
.paginations-v3-list > li.active > span:hover {
  background: #009688;
}

/* Pagers v1 */
.pagers-v1:hover {
  background: #009688;
}

/* Pagers v2 */
.pagers-v2 li > a:hover,
.pagers-v2 li > span:hover {
  border-color: #009688;
  background: #009688;
}

/* Tab v1 */
.tab-v1 .nav-tabs > li:hover > a {
  color: #009688;
}

.tab-v1 .nav-tabs > li.active > a {
  color: #009688;
}

/* Tab v3 */
.tab-v3 .nav-tabs > li:hover > a {
  color: #009688;
}

.tab-v3 .nav-tabs > li.active > a {
  background: #009688;
}

/* Tab v4 */
.tab-v4 .nav-tabs {
  background: #009688;
}

.tab-v4 .nav-tabs.nav-tabs-left > li:hover > a {
  background: #006d63;
}

.tab-v4 .nav-tabs.nav-tabs-left > li.active > a {
  background: #006d63;
}

/* Tab v5 (left) */
.tab-v5 .nav-tabs.nav-tabs-left > li:hover > a {
  color: #009688;
}

.tab-v5 .nav-tabs.nav-tabs-left > li.active > a {
  color: #009688;
}

/* Tab v6 (right) */
.tab-v6 .nav-tabs.nav-tabs-right > li:hover > a {
  color: #009688;
}

.tab-v6 .nav-tabs.nav-tabs-right > li.active > a {
  color: #009688;
}

/* Theme Icons Base */
.theme-icons-wrap > a[href] > .theme-icons-base-hover:hover {
  background: #009688;
}

/* Theme Icons Base Background */
.theme-icons-base-bg {
  display: inline-block;
  color: #fff;
  background: #009688;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-base-bg:hover {
  background: #45b4a9;
}

/* Theme Icons White Background */
.theme-icons-wrap > a[href] > .theme-icons-white-bg:hover {
  background: #009688;
}

/* Theme Icons Grey Light Background */
.theme-icons-wrap > a[href] > .theme-icons-grey-light-bg:hover {
  background: #009688;
}

/* Theme Icons Base Bordered */
.theme-icons-base-brd {
  display: inline-block;
  color: #009688;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #009688;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-base-brd:hover {
  background: #009688;
  border-color: #009688;
}

/* Animate Theme Icons Base */
.animate-theme-icons .animate-theme-icons-base {
  color: #009688;
}

/* Animate Theme Icons Base Background */
.animate-theme-icons .animate-theme-icons-base-bg {
  background: #009688;
}

/* Animate Theme Icons Base Bordered */
.animate-theme-icons .animate-theme-icons-base-brd {
  color: #009688;
  border-color: #009688;
}

/* Theme Icons Elegant */
.theme-icons-elegant .theme-icons-element .theme-icons-wrap > a[href] > .theme-icons:hover {
  background: #009688;
}

/* TimeLine Current Date */
.timeline-v1-current-date .timeline-v1-current-day {
  color: #009688;
}

.timeline-v1-current-date .timeline-v1-current-time {
  color: #009688;
}

/* TimeLine v2 */
.timeline-v2 .timeline-v2-news-title > a:hover {
  color: #009688;
}

.timeline-v2 .timeline-v2-current-day {
  color: #009688;
}

.timeline-v2 .timeline-v2-current-time {
  color: #009688;
}

/* TimeLine v3 */
.timeline-v3 .timeline-v3-list-item .timeline-v3-badge-icon {
  background: #009688;
}

/* TimeLine v4 */
.timeline-v4:before {
  background: #009688;
}

.timeline-v4:after {
  background: #009688;
}

.timeline-v4 .timeline-v4-list-item .timeline-v4-badge-icon {
  background: #009688;
}

.timeline-v4 .timeline-v4-subtitle {
  color: #009688;
}

/* Counters v1 */
.counters-v1 .counters-v1-body .counters-v1-number {
  color: #009688;
}

/* Counters v3 */
.counters-v3 .counters-v3-icon {
  color: #009688;
}

/* Icons Box v2 */
.icon-box-v2 .icon-box-v2-icons {
  color: #009688;
}

/* Image Gallery */
.image-gallery .image-gallery-overlay {
  background: rgba(0, 150, 136, 0.6);
}

/* Image Gallery v2 */
.image-gallery-v2 .image-gallery-v2-overlay {
  background: rgba(0, 150, 136, 0.6);
}

/* Interactive Banner v2 */
.i-banner-v2 .i-banner-v2-heading .i-banner-v2-heading-month {
  color: #009688;
}

/* Interactive Banner v3 */
.i-banner-v3 .i-banner-v3-content .i-banner-v3-subtitle {
  color: #009688;
}

.i-banner-v3 .i-banner-v3-content .i-banner-v3-element .i-banner-v3-element-icon {
  color: #009688;
}

/* Newsletter v5 */
.newsletter-v5 .newsletter-v5-btn:hover {
  color: #009688;
}

/* Newsletter v8 */
.newsletter-v8:hover {
  background: #009688;
}

/* Poster v1 */
.poster-v1 .poster-v1-body .poster-v1-author {
  color: #009688;
}

/* Poster v2 */
.poster-v2 .poster-v2-subtitle {
  color: #009688;
}

/* Pricing List v1 Active */
.pricing-list-v1-active .pricing-list-v1-body .pricing-list-v1-header-price,
.pricing-list-v1-active .pricing-list-v1-body .pricing-list-v1-header-price-sign,
.pricing-list-v1-active .pricing-list-v1-body .pricing-list-v1-header-price-info {
  color: #009688;
}

/* Process v1 */
.process-v1 .process-v1-back {
  background: #009688;
}

/* Process v2 */
.process-v2:hover .process-v2-element {
  background: #009688;
}

/* Service Block v4 */
.service-block-v4 .service-block-v4-content .service-block-v4-content-subtitle {
  color: #009688;
}

/* Service Block v6 */
.service-block-v6.service-block-v6-color-three:before {
  background: rgba(0, 150, 136, 0.85);
}

/* Service Block v8 */
.service-block-v8 .service-block-v8-subtitle {
  color: #009688;
}

/* Slider Block v1 */
.slider-block-v1 .slider-block-v1-subtitle {
  color: #009688;
}

/* Team v1 */
.team-v1 .team-v1-content .team-v1-socials .team-v1-socials-link:hover {
  color: #009688;
}

/* Team v2 */
.team-v2 .team-v2-member-position {
  color: #009688;
}

/* Team v3 */
.team-v3 .team-v3-header .team-v3-member-position {
  color: #009688;
}

/* Team v5 */
.team-v5 .team-v5-content .team-v5-author-contact:hover {
  color: #009688;
}

/* Testimonials v1 */
.testimonials-v1 .testimonials-v1-author {
  color: #009688;
}

/* Testimonials v4 */
.testimonials-v4 .testimonials-v4-author {
  color: #009688;
}

/* Testimonials v5 */
.testimonials-v5 .testimonials-v5-author {
  color: #009688;
}

/* Testimonials v6 */
.testimonials-v6 .testimonials-v6-element .testimonials-v6-author {
  color: #009688;
}

/* Testimonials v7 */
.testimonials-v7 .testimonials-v7-author {
  color: #009688;
}

.testimonials-v7 .testimonials-v7-author:before {
  background: #009688;
}

/* Footer Top */
.footer .footer-address .footer-address-link:hover {
  color: #009688;
}

/* Footer Media */
.footer .footer-media .footer-media-item .footer-media-link:hover {
  color: #009688;
}

/* Footer Tags */
.footer .footer-tags > li > a:hover {
  background: #009688;
  border-color: #009688;
}

/* Footer News List */
.footer .footer-news-list .footer-news-list-item .footer-news-list-link:hover {
  color: #009688;
}

/* Footer Video Info */
.footer .footer-video-title .footer-video-title-link:hover {
  color: #009688;
}

/* Footer Copyright */
.footer .footer-copyright .footer-copyright-item > a:hover {
  color: #009688;
}

/* Footer Toggle Content */
.footer .footer-toggle .footer-toggle-link:hover {
  color: #009688;
}

/* Footer v2 */
.footer-v2 {
  background: #009688;
}

/* Footer v4 */
.footer-v4 .footer-v4-service .footer-v4-service-item .footer-v4-service-icon {
  color: #009688;
}

.footer-v4 .footer-v4-service .footer-v4-service-item .footer-v4-service-link:hover {
  color: #009688;
}

.footer-v4 .footer-v4-news .footer-v4-news-item .footer-v4-news-link:hover {
  color: #009688;
}

.footer-v4 .footer-v4-link:hover {
  color: #009688;
}

.footer-v4 .footer-v4-copyright .footer-v4-terms-list .footer-v4-terms-link:hover {
  color: #009688;
}

/* Footer v5 */
.footer-v5 .footer-v5-content-subtitle {
  color: #009688;
}

.footer-v5 .footer-v5-list .footer-v5-list-item .footer-v5-list-link:hover {
  color: #009688;
}

/* Copyright */
.footer-v5-copyright .footer-v5-copright-socials .footer-v5-copright-socials-item .footer-v5-copright-socials-link:hover {
  color: #009688;
}

/* Footer v6 */
.footer-v6 .footer-link-v6-list .footer-link-v6-list-item .footer-link-v6-list-link:hover {
  color: #009688;
}

/* Form Modal */
.form-modal .form-modal-back-btn-message .form-modal-back-btn-message-link {
  color: #009688;
}

.form-modal .form-modal-input-group .form-modal-hide-password:hover {
  color: #009688;
}

.form-modal .form-modal-close-form:hover {
  color: #009688;
}

/* Navbar Toggle */
.header .navbar-toggle:hover .toggle-icon {
  background: #009688;
}

.header .navbar-toggle:hover .toggle-icon:before, .header .navbar-toggle:hover .toggle-icon:after {
  background: #009688;
}

/* Topbar Toggle Trigger */
.header .topbar-toggle-trigger:hover .topbar-toggle-trigger-style {
  background: #009688;
}

.header .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:before, .header .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:after {
  background: #009688;
}

/* Topbar Toggle Trigger Icon */
.header .topbar-toggle-trigger-icon-style:hover {
  color: #009688;
}

/* Shopping Cart */
.header .shopping-cart .shopping-cart-icon:hover {
  color: #009688;
}

.header .shopping-cart .shopping-cart-menu {
  border-top-color: #009688;
}

.header .shopping-cart .shopping-cart-menu-product-name:hover {
  color: #009688;
}

.header .shopping-cart .shopping-cart-close:hover {
  color: #009688;
}

.header .shopping-cart .shopping-cart-subtotal-price {
  color: #009688;
}

.header .shopping-cart .shopping-cart-subtotal-view-link:hover {
  color: #009688;
}

/* Navbar Nav */
.header .navbar-nav .nav-item-child.active {
  color: #009688;
}

.header .navbar-nav .nav-item-child.active:hover {
  color: #009688;
}

.header .navbar-nav .nav-item-child:hover, .header .navbar-nav .nav-item-child:focus {
  color: #009688;
}

/* Dropdown Menu */
.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active {
  color: #009688;
}

.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active:hover {
  color: #009688;
}

.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:hover {
  background: #009688;
}

.header .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child {
  color: #009688;
}

/* Dropdown Submenu */
.header .dropdown-submenu:hover .dropdown-submenu-child {
  color: #009688;
}

.header .dropdown-submenu .dropdown-submenu-child.active {
  color: #009688;
}

.header .dropdown-submenu .dropdown-submenu-child.active:hover {
  color: #009688;
}

.header .dropdown-submenu .dropdown-submenu-child.active:hover:after {
  color: #009688;
}

.header .dropdown-submenu .dropdown-submenu-child:hover {
  color: #fff;
  background: #009688;
}

.header .dropdown-submenu .dropdown-submenu-child:hover:after {
  color: #fff;
}

.header .dropdown-submenu.active .dropdown-submenu-child {
  color: #009688;
}

.header .dropdown-submenu.active .dropdown-submenu-child:hover {
  color: #009688;
}

/* Mega Menu */
.header .mega-menu-list .mega-menu-child.active {
  color: #009688;
}

.header .mega-menu-list .mega-menu-child.active:hover {
  color: #009688;
}

.header .mega-menu-list .mega-menu-child:hover {
  background: #009688;
}

/* Media Queries below 991px */
@media (max-width: 991px) {
  /* Navbar Nav */
  .header .navbar-nav .nav-item-bg {
    border-color: #009688;
  }
  .header .navbar-nav .nav-item-bg .nav-item-child {
    background: #009688;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Navbar */
  .header .navbar-nav .nav-item:hover .nav-item-child {
    color: #009688;
  }
  .header .navbar-nav .nav-item-bg {
    border-color: #009688;
  }
  .header .navbar-nav .nav-item-bg .nav-item-child {
    background: #009688;
  }
  .header .navbar-nav .nav-item-bg .nav-item-child:hover {
    color: #fff;
  }
}

/* Navbar Toggle */
.header-center-aligned .navbar-toggle:hover .toggle-icon {
  background: #009688;
}

.header-center-aligned .navbar-toggle:hover .toggle-icon:before, .header-center-aligned .navbar-toggle:hover .toggle-icon:after {
  background: #009688;
}

/* Topbar Toggle Trigger */
.header-center-aligned .topbar-toggle-trigger:hover .topbar-toggle-trigger-style {
  background: #009688;
}

.header-center-aligned .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:before, .header-center-aligned .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:after {
  background: #009688;
}

/* Topbar Toggle Trigger Icon */
.header-center-aligned .topbar-toggle-trigger-icon-style:hover {
  color: #009688;
}

/* Shopping Cart */
.header-center-aligned .shopping-cart .shopping-cart-icon:hover {
  color: #009688;
}

.header-center-aligned .shopping-cart .shopping-cart-menu {
  border-top-color: #009688;
}

.header-center-aligned .shopping-cart .shopping-cart-menu-product-name:hover {
  color: #009688;
}

.header-center-aligned .shopping-cart .shopping-cart-close:hover {
  color: #009688;
}

.header-center-aligned .shopping-cart .shopping-cart-subtotal-price {
  color: #009688;
}

.header-center-aligned .shopping-cart .shopping-cart-subtotal-view-link:hover {
  color: #009688;
}

/* Navbar Nav */
.header-center-aligned .navbar-nav .nav-item-child.active {
  color: #009688;
}

.header-center-aligned .navbar-nav .nav-item-child.active:hover {
  color: #009688;
}

.header-center-aligned .navbar-nav .nav-item-child:hover, .header-center-aligned .navbar-nav .nav-item-child:focus {
  color: #009688;
}

/* Dropdown Menu */
.header-center-aligned .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active {
  color: #009688;
}

.header-center-aligned .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active:hover {
  color: #009688;
}

.header-center-aligned .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:hover {
  background: #009688;
}

.header-center-aligned .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child {
  color: #009688;
}

/* Dropdown Submenu */
.header-center-aligned .dropdown-submenu:hover .dropdown-submenu-child {
  color: #009688;
}

.header-center-aligned .dropdown-submenu .dropdown-submenu-child.active {
  color: #009688;
}

.header-center-aligned .dropdown-submenu .dropdown-submenu-child.active:hover {
  color: #009688;
}

.header-center-aligned .dropdown-submenu .dropdown-submenu-child.active:hover:after {
  color: #009688;
}

.header-center-aligned .dropdown-submenu .dropdown-submenu-child:hover {
  color: #fff;
  background: #009688;
}

.header-center-aligned .dropdown-submenu .dropdown-submenu-child:hover:after {
  color: #fff;
}

.header-center-aligned .dropdown-submenu.active .dropdown-submenu-child {
  color: #009688;
}

.header-center-aligned .dropdown-submenu.active .dropdown-submenu-child:hover {
  color: #009688;
}

/* Mega Menu */
.header-center-aligned .mega-menu-list .mega-menu-child.active {
  color: #009688;
}

.header-center-aligned .mega-menu-list .mega-menu-child.active:hover {
  color: #009688;
}

.header-center-aligned .mega-menu-list .mega-menu-child:hover {
  background: #009688;
}

/* Media Queries below 991px */
@media (max-width: 991px) {
  .header-center-aligned .navbar-nav .nav-item-bg {
    border-color: #009688;
  }
  .header-center-aligned .navbar-nav .nav-item-bg .nav-item-child {
    background: #009688;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Navbar */
  .header-center-aligned .navbar-nav .nav-item:hover .nav-item-child {
    color: #009688;
  }
  .header-center-aligned .navbar-nav .nav-item-bg {
    border-color: #009688;
  }
  .header-center-aligned .navbar-nav .nav-item-bg .nav-item-child {
    background: #009688;
  }
  .header-center-aligned .navbar-nav .nav-item-bg .nav-item-child:hover {
    color: #fff;
  }
}

/* Navbar Toggle */
.header-center-aligned-transparent .navbar-toggle:hover .toggle-icon {
  background: #009688;
}

.header-center-aligned-transparent .navbar-toggle:hover .toggle-icon:before, .header-center-aligned-transparent .navbar-toggle:hover .toggle-icon:after {
  background: #009688;
}

/* Topbar Toggle Trigger */
.header-center-aligned-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style {
  background: #009688;
}

.header-center-aligned-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:before, .header-center-aligned-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:after {
  background: #009688;
}

/* Topbar Toggle Trigger Icon */
.header-center-aligned-transparent .topbar-toggle-trigger-icon-style:hover {
  color: #009688;
}

/* Shopping Cart */
.header-center-aligned-transparent .shopping-cart .shopping-cart-icon:hover {
  color: #009688;
}

.header-center-aligned-transparent .shopping-cart .shopping-cart-menu {
  border-top-color: #009688;
}

.header-center-aligned-transparent .shopping-cart .shopping-cart-menu-product-name:hover {
  color: #009688;
}

.header-center-aligned-transparent .shopping-cart .shopping-cart-close:hover {
  color: #009688;
}

.header-center-aligned-transparent .shopping-cart .shopping-cart-subtotal-price {
  color: #009688;
}

.header-center-aligned-transparent .shopping-cart .shopping-cart-subtotal-view-link:hover {
  color: #009688;
}

/* Navbar Nav */
.header-center-aligned-transparent .navbar-nav .nav-item-child.active {
  color: #009688;
}

.header-center-aligned-transparent .navbar-nav .nav-item-child.active:hover {
  color: #009688;
}

.header-center-aligned-transparent .navbar-nav .nav-item-child:hover, .header-center-aligned-transparent .navbar-nav .nav-item-child:focus {
  color: #009688;
}

/* Dropdown Menu */
.header-center-aligned-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active {
  color: #009688;
}

.header-center-aligned-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active:hover {
  color: #009688;
}

.header-center-aligned-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:hover {
  background: #009688;
}

.header-center-aligned-transparent .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child {
  color: #009688;
}

/* Dropdown Submenu */
.header-center-aligned-transparent .dropdown-submenu:hover .dropdown-submenu-child {
  color: #009688;
}

.header-center-aligned-transparent .dropdown-submenu .dropdown-submenu-child.active {
  color: #009688;
}

.header-center-aligned-transparent .dropdown-submenu .dropdown-submenu-child.active:hover {
  color: #009688;
  background: #fff;
}

.header-center-aligned-transparent .dropdown-submenu .dropdown-submenu-child.active:hover:after {
  color: #009688;
}

.header-center-aligned-transparent .dropdown-submenu .dropdown-submenu-child:hover {
  color: #fff;
  background: #009688;
}

.header-center-aligned-transparent .dropdown-submenu.active .dropdown-submenu-child {
  color: #009688;
}

.header-center-aligned-transparent .dropdown-submenu.active .dropdown-submenu-child:hover {
  color: #009688;
}

/* Mega Menu */
.header-center-aligned-transparent .mega-menu-list .mega-menu-child.active {
  color: #009688;
}

.header-center-aligned-transparent .mega-menu-list .mega-menu-child.active:hover {
  color: #009688;
}

.header-center-aligned-transparent .mega-menu-list .mega-menu-child:hover {
  background: #009688;
}

/* Media Queries below 991px */
@media (max-width: 991px) {
  /* Navbar Nav */
  .header-center-aligned-transparent .navbar-nav .nav-item-bg {
    border-color: #009688;
  }
  .header-center-aligned-transparent .navbar-nav .nav-item-bg .nav-item-child {
    background: #009688;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Header Shrink */
  .header-shrink.header-center-aligned-transparent .shopping-cart .shopping-cart-icon:hover {
    color: #009688;
  }
  /* Navbar */
  .header-center-aligned-transparent .navbar-nav .nav-item:hover .nav-item-child {
    color: #009688;
  }
  .header-center-aligned-transparent .navbar-nav .nav-item-child.active {
    color: #009688;
  }
  .header-center-aligned-transparent .navbar-nav .nav-item-bg {
    border-color: #009688;
  }
  .header-center-aligned-transparent .navbar-nav .nav-item-bg .nav-item-child {
    color: #fff;
    background: #009688;
  }
  .header-center-aligned-transparent .navbar-nav .nav-item-bg .nav-item-child:hover {
    color: #fff;
  }
}

/* Navbar */
.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon {
  background: #009688;
}

.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:before, .header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:after {
  background: #009688;
}

.header-fullscreen .header-fullscreen-nav-actions-right .nav-item-child {
  background: #009688;
}

/* Nav Overlay */
.header-fullscreen .header-fullscreen-nav-bg-overlay .header-fullscreen-nav-close:hover {
  color: #009688;
}

/* Menu */
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown.active, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown:hover, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover {
  color: #009688;
}

.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown.active:before, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown.active:after, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:before, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:after, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:before, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:after, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:before, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:after,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:before,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:after,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:before,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:after,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:before,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:after,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:before,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:after {
  color: #009688;
}

.header-fullscreen .header-fullscreen-menu .nav-dropdown-menu .nav-dropdown-menu-link:hover {
  color: #009688;
}

.header-fullscreen .header-fullscreen-menu .nav-dropdown-menu .nav-dropdown-menu-child.active {
  color: #009688;
}

.header-fullscreen .header-fullscreen-menu .nav-dropdown-menu .nav-dropdown-menu-child:hover {
  color: #009688;
}

/* Media Queries below 991px */
@media (max-width: 991px) {
  /* Nav Trigger */
  .header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon {
    background: #009688;
  }
  .header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:before, .header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:after {
    background: #009688;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Header Shrink */
  .header-shrink.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon {
    background: #009688;
  }
  .header-shrink.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:before, .header-shrink.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:after {
    background: #009688;
  }
}

/* Navbar Toggle */
.header-section-scroll .navbar-toggle:hover .toggle-icon {
  background: #009688;
}

.header-section-scroll .navbar-toggle:hover .toggle-icon:before, .header-section-scroll .navbar-toggle:hover .toggle-icon:after {
  background: #009688;
}

/* Navbar Nav */
.header-section-scroll .header-section-scroll-menu .nav-item.active .nav-item-child:hover, .header-section-scroll .header-section-scroll-menu .nav-item.active .nav-item-child:focus {
  color: #009688;
}

.header-section-scroll .header-section-scroll-menu .nav-item-child:hover {
  color: #009688;
}

/* Navbar Toggle */
.header-transparent .navbar-toggle:hover .toggle-icon {
  background: #009688;
}

.header-transparent .navbar-toggle:hover .toggle-icon:before, .header-transparent .navbar-toggle:hover .toggle-icon:after {
  background: #009688;
}

/* Topbar Toggle Trigger */
.header-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style {
  background: #009688;
}

.header-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:before, .header-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:after {
  background: #009688;
}

/* Topbar Toggle Trigger Icon */
.header-transparent .topbar-toggle-trigger-icon-style:hover {
  color: #009688;
}

/* Shopping Cart */
.header-transparent .shopping-cart .shopping-cart-icon:hover {
  color: #009688;
}

.header-transparent .shopping-cart .shopping-cart-menu {
  border-top-color: #009688;
}

.header-transparent .shopping-cart .shopping-cart-menu-product-name:hover {
  color: #009688;
}

.header-transparent .shopping-cart .shopping-cart-close:hover {
  color: #009688;
}

.header-transparent .shopping-cart .shopping-cart-subtotal-price {
  color: #009688;
}

.header-transparent .shopping-cart .shopping-cart-subtotal-view-link:hover {
  color: #009688;
}

/* Navbar Nav */
.header-transparent .navbar-nav .nav-item-child.active {
  color: #009688;
}

.header-transparent .navbar-nav .nav-item-child.active:hover {
  color: #009688;
}

.header-transparent .navbar-nav .nav-item-child:hover, .header-transparent .navbar-nav .nav-item-child:focus {
  color: #009688;
}

/* Dropdown Menu */
.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active {
  color: #009688;
}

.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active:hover {
  color: #009688;
}

.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:hover {
  background: #009688;
}

.header-transparent .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child {
  color: #009688;
}

/* Dropdown Submenu */
.header-transparent .dropdown-submenu:hover .dropdown-submenu-child {
  color: #009688;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child.active {
  color: #009688;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child.active:hover {
  color: #009688;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child.active:hover:after {
  color: #009688;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child:hover {
  color: #fff;
  background: #009688;
}

.header-transparent .dropdown-submenu.active .dropdown-submenu-child {
  color: #009688;
}

.header-transparent .dropdown-submenu.active .dropdown-submenu-child:hover {
  color: #009688;
}

/* Mega Menu */
.header-transparent .mega-menu-list .mega-menu-child.active {
  color: #009688;
}

.header-transparent .mega-menu-list .mega-menu-child.active:hover {
  color: #009688;
}

.header-transparent .mega-menu-list .mega-menu-child:hover {
  background: #009688;
}

/* Media Queries below 991px */
@media (max-width: 991px) {
  /* Navbar Nav */
  .header-transparent .navbar-nav .nav-item-bg {
    border-color: #009688;
  }
  .header-transparent .navbar-nav .nav-item-bg .nav-item-child {
    background: #009688;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Header Shrink */
  .header-shrink.header-transparent .shopping-cart .shopping-cart-icon:hover {
    color: #009688;
  }
  /* Navbar */
  .header-transparent .navbar-nav .nav-item:hover .nav-item-child {
    color: #009688;
  }
  .header-transparent .navbar-nav .nav-item-child.active {
    color: #009688;
  }
  .header-transparent .navbar-nav .nav-item-bg {
    border-color: #009688;
  }
  .header-transparent .navbar-nav .nav-item-bg .nav-item-child {
    background: #009688;
  }
  .header-transparent .navbar-nav .nav-item-bg .nav-item-child:hover {
    color: #fff;
    background: #009688;
  }
  .header-transparent .navbar-nav .nav-item-bg:hover .nav-item-child {
    color: #fff;
    background: #009688;
  }
}

/* Navbar Toggle */
.header-vertical .navbar-toggle:hover .toggle-icon {
  background: #009688;
}

.header-vertical .navbar-toggle:hover .toggle-icon:before, .header-vertical .navbar-toggle:hover .toggle-icon:after {
  background: #009688;
}

/* Navbar Nav */
.header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown.active, .header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown:hover, .header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active, .header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover {
  color: #009688;
}

.header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown.active:before, .header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown.active:after, .header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:before, .header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:after, .header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:before, .header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:after, .header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:before, .header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:after,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:before,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:after,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:before,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:after,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:before,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:after,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:before,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:after {
  color: #009688;
}

.header-vertical .header-vertical-menu .nav-dropdown-menu .nav-dropdown-menu-link:hover {
  color: #009688;
}

.header-vertical .header-vertical-menu .nav-dropdown-menu .nav-dropdown-menu-child.active {
  color: #009688;
}

.header-vertical .header-vertical-menu .nav-dropdown-menu .nav-dropdown-menu-child:hover {
  color: #009688;
}

/* Search */
.search .search-btn .search-btn-default:hover,
.search .search-btn .search-btn-active:hover {
  color: #009688;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  /* Search */
  .search.search-white .search-btn .search-btn-default:hover,
  .search.search-white .search-btn .search-btn-active:hover {
    color: #009688;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Search */
  .search.search-white .search-btn .search-btn-default:hover,
  .search.search-white .search-btn .search-btn-active:hover {
    color: #009688;
  }
  /* Header Shrink */
  .header-shrink .search.search-white .search-btn .search-btn-default:hover,
  .header-shrink .search.search-white .search-btn .search-btn-active:hover {
    color: #009688;
  }
}

/* Search Classic */
.search-classic .search-classic-btn .search-classic-btn-default:hover,
.search-classic .search-classic-btn .search-classic-btn-active:hover {
  color: #009688;
}

.search-classic .search-classic-field {
  border-top-color: #009688;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  /* Search Classic */
  .search-classic.search-classic-white .search-classic-btn .search-classic-btn-default:hover,
  .search-classic.search-classic-white .search-classic-btn .search-classic-btn-active:hover {
    color: #009688;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Search Classic */
  .search-classic.search-classic-white .search-classic-btn .search-classic-btn-default:hover,
  .search-classic.search-classic-white .search-classic-btn .search-classic-btn-active:hover {
    color: #009688;
  }
  /* Header Shrink */
  .header-shrink .search-classic.search-classic-white .search-classic-btn .search-classic-btn-default:hover,
  .header-shrink .search-classic.search-classic-white .search-classic-btn .search-classic-btn-active:hover {
    color: #009688;
  }
}

/* Search Fullscreen */
.search-fullscreen .search-fullscreen-trigger-icon:hover {
  color: #009688;
}

.search-fullscreen .search-fullscreen-input-group .search-fullscreen-search:hover .search-fullscreen-search-icon {
  color: #009688;
}

.search-fullscreen .search-fullscreen-bg-overlay .search-fullscreen-close:hover {
  color: #009688;
}

/* Media Queries below 992px */
@media (min-width: 992px) {
  /* Search Fullscreen */
  .search-fullscreen.search-fullscreen-trigger-white .search-fullscreen-trigger-icon:hover {
    color: #009688;
  }
  /* Header Shrink */
  .header-shrink .search-fullscreen.search-fullscreen-trigger-white .search-fullscreen-trigger-icon:hover {
    color: #009688;
  }
}

/* Search On Header */
.search-on-header .search-on-header-btn .search-on-header-btn-default:hover,
.search-on-header .search-on-header-btn .search-on-header-btn-active:hover {
  color: #009688;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  /* Search On Header */
  .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-default:hover,
  .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-active:hover {
    color: #009688;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Search On Header */
  .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-default:hover,
  .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-active:hover {
    color: #009688;
  }
  /* Header Shrink */
  .header-shrink .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-default:hover,
  .header-shrink .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-active:hover {
    color: #009688;
  }
}

/* Search Push */
.search-push .search-push-btn .search-push-btn-default:hover,
.search-push .search-push-btn .search-push-btn-active:hover {
  color: #009688;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  /* Search Push */
  .search-push.search-push-white .search-push-btn .search-push-btn-default:hover,
  .search-push.search-push-white .search-push-btn .search-push-btn-active:hover {
    color: #009688;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Search Push */
  .search-push.search-push-white .search-push-btn .search-push-btn-default:hover,
  .search-push.search-push-white .search-push-btn .search-push-btn-active:hover {
    color: #009688;
  }
  /* Header Shrink */
  .header-shrink .search-push.search-push-white .search-push-btn .search-push-btn-default:hover,
  .header-shrink .search-push.search-push-white .search-push-btn .search-push-btn-active:hover {
    color: #009688;
  }
}

/* Sidebar Trigger */
.sidebar-trigger:hover .sidebar-trigger-icon {
  background: #009688;
}

.sidebar-trigger:hover .sidebar-trigger-icon:before, .sidebar-trigger:hover .sidebar-trigger-icon:after {
  background: #009688;
}

/* Sidebar Nav */
.sidebar-nav .sidebar-nav-trigger:hover .sidebar-trigger-icon:before, .sidebar-nav .sidebar-nav-trigger:hover .sidebar-trigger-icon:after {
  background: #009688;
}

.sidebar-nav .sidebar-nav-about-link:hover {
  color: #009688;
}

/* Media Queries below 992px */
@media (min-width: 992px) {
  /* Sidebar Trigger */
  .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon {
    background: #009688;
  }
  .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:before, .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:after {
    background: #009688;
  }
  /* Header Shrink */
  .header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon {
    background: #009688;
  }
  .header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:before, .header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:after {
    background: #009688;
  }
}

/* Topbar Contact */
.topbar-c .topbar-c-contacts .topbar-c-contacts-link:hover {
  color: #009688;
}

/* Topbar v1 Actions */
.topbar-c .topbar-c-actions .topbar-c-actions-link:hover {
  color: #009688;
}

.topbar-c .topbar-c-actions .topbar-c-actions-link-border:hover {
  background: #009688;
  border-color: #009688;
}

/* Topbar Ecommerce */
.topbar-e .topbar-e-list .topbar-e-list-item .topbar-e-list-dropdown:hover {
  color: #009688;
}

/* Topbar v2 Actions */
.topbar-e .topbar-e-actions .topbar-e-actions-link:hover {
  color: #009688;
}

/* Topbar v2 Shopping Cart */
.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-link:hover {
  color: #009688;
}

.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-menu-product-name:hover {
  color: #009688;
}

.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-close:hover {
  color: #009688;
}

.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-subtotal-price {
  color: #009688;
}

.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-subtotal-view-link:hover {
  color: #009688;
}

/* Topbar v3 List */
.topbar-t .topbar-t-list .topbar-t-list-item .topbar-t-list-dropdown:hover {
  color: #009688;
}

.topbar-t .topbar-t-list .topbar-t-list-dropdown-menu .topbar-t-list-dropdown-menu-link:hover {
  background: #009688;
}

/* Topbar v3 Shopping Cart */
.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-link:hover {
  color: #009688;
}

.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-menu-product-name:hover {
  color: #009688;
}

.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-close:hover {
  color: #009688;
}

.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-subtotal-price {
  color: #009688;
}

.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-subtotal-view-link:hover {
  color: #009688;
}

/* Contact Us */
.contact-us .contact-us-timeline-day {
  background: #009688;
}

/* Faq */
.faq .faq-search .faq-search-search:hover .faq-search-search-icon {
  color: #009688;
}

/* Faq v2 */
.faq-v2 .faq-v2-community .faq-v2-community-icon {
  color: #009688;
}

/* Animsition Loading */
.animsition-loading {
  border-left-color: #00bcd4;
}

/* Back To Top */
.back-to-top-theme {
  background: #009688;
}

.back-to-top-theme:hover {
  background: #009688;
}

/* Owl Dots */
.owl-dot span,
.owl-dots span {
  border-color: #009688;
}

.owl-dot.active span,
.owl-dots.active span {
  background: #009688;
}

/* Testimonials v4 Arrows */
.testimonials-v4-arrows .arrow-next:hover,
.testimonials-v4-arrows .arrow-prev:hover {
  background: #009688;
}

/* Owl Control Arrows v1 */
.owl-control-arrows-v1 .owl-arrow:hover {
  background: #009688;
}

/* Theme Portfolio Filter */
.theme-portfolio .cbp-filter-item.cbp-filter-item-active {
  color: #009688;
  border-color: #009688;
}

.theme-portfolio .cbp-filter-item:hover {
  color: #009688;
}

.theme-portfolio .cbp-filter-counter {
  background: #009688;
}

.theme-portfolio .cbp-filter-counter:after {
  border-top-color: #009688;
}

/* Theme Portfolio Load More Button */
.theme-portfolio .cbp-l-loadMore-button .cbp-l-loadMore-link,
.theme-portfolio .cbp-l-loadMore-button .cbp-l-loadMore-button-link,
.theme-portfolio .cbp-l-loadMore-text .cbp-l-loadMore-link,
.theme-portfolio .cbp-l-loadMore-text .cbp-l-loadMore-button-link {
  color: #009688;
  border-color: #009688;
}

.theme-portfolio .cbp-l-loadMore-button .cbp-l-loadMore-link:hover,
.theme-portfolio .cbp-l-loadMore-button .cbp-l-loadMore-button-link:hover,
.theme-portfolio .cbp-l-loadMore-text .cbp-l-loadMore-link:hover,
.theme-portfolio .cbp-l-loadMore-text .cbp-l-loadMore-button-link:hover {
  background: #009688;
}

/* Theme Portfolio Zoom Effect */
.theme-portfolio .cbp-caption-zoom .cbp-caption-activeWrap {
  background: rgba(0, 150, 136, 0.6);
}

/* Theme Portfolio Item v2 */
.theme-portfolio .theme-portfolio-item-v2:hover .theme-portfolio-active-wrap:before {
  background: rgba(0, 150, 136, 0.5);
}

/* Theme Portfolio Item v3 */
.theme-portfolio .theme-portfolio-item-v3 .theme-portfolio-lightbox .theme-portfolio-item-v3-icon:hover:before, .theme-portfolio .theme-portfolio-item-v3 .theme-portfolio-lightbox .theme-portfolio-item-v3-icon:hover:after {
  background: #009688;
}

/* Theme Portfolio Description */
.theme-portfolio-desc .portfolio-item-category:hover {
  color: #009688;
}

/* Theme Carousel Control v2 */
.theme-carousel-control-v2 .carousel-control-arrows-v2:hover {
  background: #009688;
}

/* Theme Carousel Indicators v2 */
.theme-carousel-indicators-v2 li.active {
  background: #009688;
  border-color: #009688;
}

/* Theme Carousel Indicators v3 */
.theme-carousel-indicators-v3 li.active {
  color: #009688;
  border-bottom-color: #009688;
}

.theme-carousel-indicators-v3 li:hover {
  border-bottom-color: #009688;
}

.theme-carousel-indicators-v3 li:hover.active {
  color: #009688;
  border-bottom-color: #009688;
}

/* Theme Carousel Indicators v3 */
.theme-carousel-indicators-v4 li.active {
  background: #009688;
  border-color: #009688;
}

/* Theme Carousel Indicators v5 */
.theme-carousel-indicators-v5 li.active .theme-carousel-indicators-item {
  border-color: #009688;
}

/* Revolution Slider */
/* Spinner */
.tp-loader.spinner2 {
  background: #009688;
}

/* Serviecs v6 */
.services-v6:hover:before {
  background: rgba(0, 150, 136, 0.8);
}

/* Serviecs v7 */
.services-v7 .services-v7-link-icon:hover {
  background: #009688;
  border-color: #009688;
}

/* Serviecs v8 */
.services-v8:hover {
  background: #009688;
}

/* Checkbox */
.checkbox input[type="checkbox"]:checked + label:before,
.checkbox input[type="radio"]:checked + label:before {
  background: #009688;
  border-color: #009688;
}

/* Checkbox */
.work-v1 .work-v1-badge {
  background: #009688;
}

.work-v1 .work-v1-list .work-v1-category .work-v1-category-link:hover {
  color: #009688;
}

/* Promo Block v12 */
.promo-block-v12 .promo-block-v12-form:focus {
  color: #009688;
}

.promo-block-v12 .promo-block-v12-form:focus::-moz-placeholder {
  color: #009688;
}

.promo-block-v12 .promo-block-v12-form:focus:-ms-input-placeholder {
  color: #009688;
}

.promo-block-v12 .promo-block-v12-form:focus::-webkit-input-placeholder {
  color: #009688;
}

/* Shopify Product */
.shopify-product:hover .shopify-product-name {
  color: #009688;
}

/* Language V1 */
.language-v1 .language-v1-toggle:hover {
  color: #009688;
}

.language-v1 .language-v1-dropdown-link:hover {
  background: #009688;
}

/* Language V1 White */
/* Media Queries above 992px */
@media (min-width: 992px) {
  .language-v1.language-v1-style-white .language-v1-toggle:hover {
    color: #009688;
  }
  /* Header Shrink */
  .header-shrink .language-v1.language-v1-style-white .language-v1-toggle:hover {
    color: #009688;
  }
}

/* Language Push */
.language-push .language-push-btn-icon:hover {
  color: #009688;
}

/* Language Push White */
/* Media Queries above 992px */
@media (min-width: 992px) {
  .language-push.language-push-style-white .language-push-btn-icon:hover {
    color: #009688;
  }
  /* Header Shrink */
  .header-shrink .language-push.language-push-style-white .language-push-btn-icon:hover {
    color: #009688;
  }
}

/* Newsletter v6 */
.newsletter-v6 .newsletter-v6-btn:hover {
  color: #009688;
}

/* News v11 */
.news-v11 .news-v11-subtitle {
  color: #009688;
}

/* Handle */
.image-comparison-container .image-comparison-handle {
  background: #009688;
}

/* Testimonials v2 Arrows */
.testimonials-v2-arrows .arrow-next:hover,
.testimonials-v2-arrows .arrow-prev:hover {
  background: #009688;
}

/* Background Icon */
.fuelux .radio-checkbox-bg-icon.checked:before {
  background: #009688;
  border-color: #009688;
}

/* Background Color */
.fuelux .radio-checkbox-bg-color.checked:before {
  background: #009688;
  border-color: #009688;
}

/* Border */
.fuelux .radio-checkbox-brd.checked:before {
  border-color: #009688;
}

/* Border with Dot */
.fuelux .radio-checkbox-brd-dot.checked:before {
  border-color: #009688;
}

.fuelux .radio-checkbox-brd-dot.checked:after {
  background: #009688;
}

.segment-controls {
  border-color: #009688;
}

/* Label */
.segment-controls .segment-controls-label:before {
  background: #009688;
}

/* Color Teal */
.color-base {
  color: #009688 !important;
}

/* Background Color Teal */
.bg-color-base {
  background: #009688 !important;
}
