body {
	height: inherit !important;
}
#home {
	overflow: hidden;
}
.dzsparallaxer--cta {
	@media screen and (max-width: 1050px) {
		// height: ;
		// background-position: 20% 0 !important;
	}
}


#scroll_to_content {
	p {
		font-size: 18px;
		font-style: italic;
	}
}
.map {
	height: 450px;
}

.footer {
	
	h1, h2, h3, h4 {
		color: white;
	}
	&, p, > * {
		color: #cccccc;
	}
}
#oeffnungszeiten h2.heading-v1-title, #anmeldung h2.heading-v1-title{
	margin-bottom:0.8em;
}

@media (max-width: 767px) {
	.content-md {
		padding-top: 40px;
		padding-bottom: 40px;

	}
}
@media (min-width: 768px + 1px) {
	em,
	li,
	li a,
	p {
	  font-size: 18px;
	  line-height: 1.6;
	}

	.padding_extra {
		padding:0 4rem;
	}

	
}





.heading-v1 .heading-v1-title {
	@media (max-width: 450px) {
		font-size: 23px;
	}
}

