






/*--------------------------------------------------
    [Promo Block One Page Business v7]
----------------------------------------------------*/
.op-b-promo-b-v7 {
  position: relative;
  z-index: 1;
  // background: url(../../assets/img/1920x1080/13.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 130px 0 70px;
}

.op-b-promo-b-v7:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  // background: rgba(109, 129, 156, 0.95);
  content: " ";
}

.op-b-promo-b-v7.op-b-promo-b-v7-bg-img-v1 {
  // background: url(./assets_build/img_Auswahl_Annette/AK_011316-0545_nowindow.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  // padding: 0;
}

// .op-b-promo-b-v7.op-b-promo-b-v7-bg-img-v1:before {
//   background: rgba(52, 52, 60, 0.6);
// }

// .op-b-promo-b-v7.op-b-promo-b-v7-bg-img-v2 {
//   background: url(../../assets/img/1920x1080/15.jpg) no-repeat;
//   background-size: cover;
//   background-position: center center;
// }

// .op-b-promo-b-v7.op-b-promo-b-v7-bg-img-v2:before {
//   background: rgba(52, 52, 60, 0.7);
// }

.op-b-promo-b-v7 .op-b-promo-b-v7-title {
  font-size: 50px;
  color: #fff;
  margin-bottom: 30px;
}

.op-b-promo-b-v7 .op-b-promo-b-v7-title-lg {
  font-size: 100px;
  color: #fff;
  margin-bottom: 10px;
}

.op-b-promo-b-v7 .op-b-promo-b-v7-text {
  font-size: 18px;
  color: #fff;
  opacity: .9;
  margin-bottom: 0;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .op-b-promo-b-v7 {
    padding: 50px 0;
  }
  .op-b-promo-b-v7 .op-b-promo-b-v7-title-lg {
    font-size: 70px;
  }
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .op-b-promo-b-v7 .op-b-promo-b-v7-title-lg {
    font-size: 50px;
  }
}

/* Media Queries below 992px */
@media (min-width: 992px) {
  .op-b-promo-b-v7-login {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
  }
}














/* 
 * header
 */
// #home {
//   height: 100vh;

// }
// section {
//   background-color: white;
//   &#home {
//     background-color: transparent;
//   }
// }
// .custom-header.op-b-promo-b-v7.op-b-promo-b-v7-bg-img-v1 {
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;

//   z-index: -10;
// }
.custom-header.op-b-promo-b-v7.op-b-promo-b-v7-bg-img-v1{
  /*
   * generic
   */
  
  overflow: hidden;
  .text {
    width: 90%;
    font-size: 1.5em;

    > * {
      color: #adc0c6;
    }
    // h1 {
    //   font-size: 2em;
    // }
    h1 {
      font-family: Roboto, sans-serif;
      font-size: 1em;
      font-weight: 300;
      font-style: normal;
      text-transform: uppercase;
    }
    
    p {
      font-size: 1em;
      text-transform: uppercase;
      // @media (max-width: 480px) {
      //   display: none;
      // }
      margin: 0;
    }
  }  


  /* 
   * rwd
   */
  background-repeat: no-repeat;
  @media (orientation:landscape) {
    height: 40vh;
    // @media (min-width: 1500px) {
    //   height: 35vw;
    // }
    // @media (max-width: 1281px) {
    //   height: 35vw;
    // }
    background-image: url(./assets_build/img_Auswahl_Annette/AK_011316-0545_wide3.jpg);
    @media (max-width: 2000px) {
      background-image: url(./assets_build/img_Auswahl_Annette/AK_011316-0545_wide3.jpg;2200w);
    }
    @media (max-width: 1600px) {
      background-image: url(./assets_build/img_Auswahl_Annette/AK_011316-0545_wide3.jpg;1800w);
    }
    @media (max-width: 1200px) {
      background-image: url(./assets_build/img_Auswahl_Annette/AK_011316-0545_wide3.jpg;1600w);
    }
    .text { 
      position: absolute;
      left: 50%;
      top: 65%;
      transform: translate(-30%, -50%);
      @media (max-width: 1281px) {
        font-size: 1.4em;
      }
      @media (max-width: 1200px) {
        font-size: 1.3em;
      }
      @media (max-width: 992px) {
        font-size: 1.1em;
      }
      
    }
  }

  @media (orientation:portrait) {
    background-image: url(./assets_build/img_Auswahl_Annette/AK_011316-0545_wide3.jpg);
    @media (max-width: 2000px) {
      background-image: url(./assets_build/img_Auswahl_Annette/AK_011316-0545_center.jpg;2200w);
    }
    @media (max-width: 1600px) {
      background-image: url(./assets_build/img_Auswahl_Annette/AK_011316-0545_center.jpg;1800w);
    }
    @media (max-width: 1300px) {
      background-image: url(./assets_build/img_Auswahl_Annette/AK_011316-0545_center.jpg;1600w);
    }
    @media (max-width: 800px) {
      background-image: url(./assets_build/img_Auswahl_Annette/AK_011316-0545_center.jpg;1200w);
    }
    @media (max-width: 500px) {
      background-image: url(./assets_build/img_Auswahl_Annette/AK_011316-0545_center.jpg;900w);
    }
    .scroll-to-section-v1 {
      display: none;
    }
    height: 30vh;
    // @media (max-width: 400px) {
    //   height: 75vh;
    // }

    // margin-bottom: 14em;
    margin-bottom: 10em;
    @media (max-width: 550px) {
      // margin-bottom: 12em;
      margin-bottom: 7em;
    }
    @media (max-width: 400px) {
      // margin-bottom: 15em;
    }
    overflow: visible;
    .text {
      > * {
        color: #523725;  
      }
      
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 120%);
      @media (max-width: 1281px) {
        font-size: 1.7em;
      }
      @media (max-width: 660px) {
        font-size: 1.5em;
        transform: translate(-50%, 130%);
      }
      @media (max-width: 550px) {
        font-size: 1.2em;
      }
      @media (max-width: 450px) {
        font-size: 1.1em;
        // font-size: 1.2em;
        // transform: translate(-50%, 25%);
      }
    }
  }


  
}












